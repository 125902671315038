import model from './model';
import { ProjectHeaderWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectHeaderWidgetProps as Props } from './config/types';
import * as imageSdk from '@wix/image-client-api/dist/imageClientSDK';
import _ from 'lodash';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    description: $w(ComponentIds.Description),
    image: $w(ComponentIds.HeroImage),
    details: $w(ComponentIds.Details),
  });

  const initByProps = (props: Props) => {
    const { data } = props;
    const { title, details, description, heroImage } = data;
    components.title.text = title;
    components.details.data = { projectDetails: details };
    components.description.text = description;
    if (heroImage?.imageInfo?.id) {
      const ratio = 1.9;
      let height = 300;
      let width = 300 * ratio;
      if (!flowAPI.environment.isSSR) {
        width = 1920;
        height = width / ratio;
      }
      components.image.src =
        imageSdk.getScaleToFillImageURL(
          heroImage.imageInfo.id,
          heroImage.imageInfo.width,
          heroImage.imageInfo.height,
          width,
          height,
          {
            focalPoint: {
              x: (heroImage.focalPoint?.x || 0.5) * 100,
              y: (heroImage.focalPoint?.y || 0.5) * 100,
            },
          },
        ) + (heroImage.token ? '?token=' + heroImage.token : '');
    } else if (!_.isEmpty(heroImage)) {
      components.image.src = heroImage;
    }
  };

  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    const isWidgetOnStage = !!components.topContainer.id;
    if (isWidgetOnStage) {
      initByProps(nextProps);
    }
  });

  return {
    pageReady: async () => { },
    exports: {},
  };
});
